import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  filter,
} from 'rxjs';
import {
  PimProduct,
  SearchResultGroup,
} from 'src/app/modules/pim-graphql/views/search-view/search.model';
import { SearchService } from 'src/app/modules/pim-graphql/views/search-view/search.service';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  searchInputControl = new FormControl<string | PimProduct>('');
  inputFocused = false;
  inputContainsText = false;
  loading = false;
  suggestions$?: Observable<SearchResultGroup[]>;
  private destroy$ = new Subject<void>();

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger?: MatAutocompleteTrigger;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private locales: LocaleService,
    private routePipe: PimRoutePipe,
  ) {}

  ngOnInit(): void {
    this.suggestions$ = this.searchInputControl.valueChanges.pipe(
      tap((searchTerm) => {
        this.inputContainsText = !!(searchTerm && searchTerm !== '');
      }),
      debounceTime(500),
      distinctUntilChanged(),
      filter((query) => typeof query === 'string' && query.length > 1),
      takeUntil(this.destroy$),
      switchMap((value) => {
        this.loading = true;
        return this.valueChanged(value).pipe(tap(() => (this.loading = false)));
      }),
    );

    this.locales.currentLocale$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onCloseClick());
  }

  private valueChanged(
    searchTerm: string | PimProduct | null,
  ): Observable<SearchResultGroup[]> {
    if (searchTerm && typeof searchTerm !== 'string') {
      searchTerm = searchTerm.name;
    }

    if (!this.inputContainsText || !searchTerm) return of([]);

    return this.searchService.search(searchTerm.trim());
  }

  displayResult(result: PimProduct) {
    return result.name;
  }

  focusInCheck() {
    this.inputFocused = true;
    this.inputContainsText = !!this.searchInputControl.value;
  }

  focusOutCheck(searchTerm: string) {
    this.inputFocused = false;
    if (!searchTerm) {
      this.inputContainsText = false;
    }
  }

  goToResult(result: PimProduct) {
    //Disable autocomplete temporarily to prevent refocusing
    this.autocompleteTrigger?.setDisabledState(true);
    setTimeout(() => this.autocompleteTrigger?.setDisabledState(false), 100);

    //Get locale part from current url
    const url = this.routePipe.transform(result._id, result.type);
    if (url) this.router.navigate(url);
  }

  onCloseClick(searchBarInput?: HTMLInputElement) {
    this.searchInputControl.patchValue('');
    if (searchBarInput) searchBarInput.blur();
    if (this.autocompleteTrigger) this.autocompleteTrigger.closePanel();
    this.focusOutCheck('');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
