import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  env: 'local',
  apiUrl: 'https://api-integration.viessmann.com/',
  app: {
    defaultLocale: {
      language: 'en',
      country: 'DE',
      salesOrg: 'DE',
      flag: 'de',
      pimApiContext: { language: 'de', country: 'DE' },
    },
    supportedLocales: [
      {
        language: 'de',
        country: 'Master',
        salesOrg: '',
        flag: 'world',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'de',
        country: 'DE',
        salesOrg: 'DE',
        flag: 'de',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'en',
        country: 'DE',
        salesOrg: 'DE',
        flag: 'de',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'de',
        country: 'AT',
        salesOrg: 'AT',
        flag: 'at',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'de',
        country: 'CH',
        salesOrg: 'CH',
        flag: 'ch',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'fr',
        country: 'CH',
        salesOrg: 'CH',
        flag: 'ch',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'it',
        country: 'CH',
        salesOrg: 'CH',
        flag: 'ch',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'da',
        country: 'DK',
        salesOrg: 'DK',
        flag: 'dk',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'pl',
        country: 'PL',
        salesOrg: 'PL',
        flag: 'pl',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'fr',
        country: 'FR',
        salesOrg: 'FR',
        flag: 'fr',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'nl',
        country: 'NL',
        salesOrg: 'NL',
        flag: 'nl',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'fr',
        country: 'BE',
        salesOrg: 'BE',
        flag: 'be',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'nl',
        country: 'BE',
        salesOrg: 'BE',
        flag: 'be',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'fr',
        country: 'LU',
        salesOrg: 'LU',
        flag: 'lu',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'de',
        country: 'LU',
        salesOrg: 'LU',
        flag: 'lu',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'it',
        country: 'IT',
        salesOrg: 'IT',
        flag: 'it',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'de',
        country: 'IT',
        salesOrg: 'IT',
        flag: 'it',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'es',
        country: 'ES',
        salesOrg: 'ES',
        flag: 'es',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'tr',
        country: 'TR',
        salesOrg: 'TR',
        flag: 'tr',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'en',
        country: 'GB',
        salesOrg: 'GB',
        flag: 'gb',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'en',
        country: 'US',
        salesOrg: 'US',
        flag: 'us',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'en',
        country: 'CA',
        salesOrg: 'CA',
        flag: 'ca',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'bg',
        country: 'BG',
        salesOrg: 'BG',
        flag: 'bg',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'cs',
        country: 'CZ',
        salesOrg: 'CZ',
        flag: 'cz',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'et',
        country: 'EE',
        salesOrg: 'LT',
        flag: 'ee',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'fi',
        country: 'FI',
        salesOrg: 'FI',
        flag: 'fi',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'hr',
        country: 'HR',
        salesOrg: 'HR',
        flag: 'hr',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'hu',
        country: 'HU',
        salesOrg: 'HU',
        flag: 'hu',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'lt',
        country: 'LT',
        salesOrg: 'LT',
        flag: 'lt',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'lv',
        country: 'LV',
        salesOrg: 'LV',
        flag: 'lv',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'pt',
        country: 'PT',
        salesOrg: 'ES',
        flag: 'pt',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'ro',
        country: 'RO',
        salesOrg: 'RO',
        flag: 'ro',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'sv',
        country: 'SE',
        salesOrg: 'SE',
        flag: 'se',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'sl',
        country: 'SI',
        salesOrg: 'SI',
        flag: 'si',
        pimApiContext: { language: 'de', country: 'DE' },
      },
      {
        language: 'sk',
        country: 'SK',
        salesOrg: 'SK',
        flag: 'sk',
        pimApiContext: { language: 'de', country: 'DE' },
      },
    ],
  },
  pim: {
    baseUrl: 'https://api-integration.viessmann.com/product-data/graphql',
    workspaces: ['Main', 'Approved'],
    topClassification: 'App_Structure',
    classificationRef: 'REF_ProductToOutputStructure',
    productType: {
      productImageRef: 'PrimaryProductImage',
      productImageSubtypeId: ['130', '110', '120'], //Produktbild mit Hintergrund, Freisteller (Frontale), Freisteller(Ansicht)
    },
  },
  auth: {
    appId: 'product-browser',
    baseUrl: 'https://api-integration.viessmann.com',
    registerBaseUrl: 'https://account-integration.viessmann.com',
  },
  http: {
    translationBaseUrl:
      'https://api-integration.viessmann.com/translations/v2/',
  },
  globalHeader: {
    baseUrl: 'https://api-integration.viessmann.com',
    accountAppUrl: 'https://account-integration.viessmann.com',
  },
};
