import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PimProduct } from '../modules/pim-graphql/views/search-view/search.model';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root',
})
export class PimService {
  private locale: LocaleService;

  constructor(
    private httpClient: HttpClient,
    locale: LocaleService,
  ) {
    this.locale = locale;
  }

  searchByTerm(searchTerm: string): Observable<PimProduct[]> {
    const pageSize: number = 50;
    const pageNumber: number = 0;
    const { language, country } = this.locale.getCurrentLocale().pimApiContext;
    const url = `${environment.globalHeader.baseUrl}/backend/pim/v1/products/${language.toLowerCase()}/${country.toLowerCase()}/search?keyword=${searchTerm}&pageSize=${pageSize}&page=${pageNumber}`;

    return this.httpClient.get<PimProduct[]>(url).pipe(
      map((response) => {
        return response.map((result) => {
          return {
            _id: result['_id'],
            name: result.name,
            type: result.type,
            description: result.description,
          };
        });
      }),
    );
  }
}
