import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Product } from '../../pim-graphql.model';
import { PimGraphqlService } from '../../pim-graphql.service';
import { CardData, HeaderData, PathElement } from '../shared-view.model';
import { SharedViewService } from '../shared-view.service';
import { ProductData } from './product.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private validDescriptionAttributes = [
    'AT_Efficiency_Heating',
    'AT_Efficiency_WarmWater',
    'AT_Efficiency_Storage',
    'AT_SEV_D_085',
    'AT_SEV_D_095',
    'AT_SEV_D_1',
  ];

  private validMasterDescriptionAttributes = [
    'AT_Efficiency_Heating_global',
    'AT_Efficiency_WarmWater_global',
    'AT_Efficiency_Storage_global',
    'AT_SEV_D_085_global',
    'AT_SEV_D_095_global',
    'AT_SEV_D_1_global',
  ];

  constructor(
    private pim: PimGraphqlService,
    private router: Router,
    private sharedService: SharedViewService,
    private routePipe: PimRoutePipe,
  ) {}

  getProductData(id: string): Observable<ProductData | undefined> {
    return this.pim.getProductById(id).pipe(
      map((product) => {
        if (!product || product.objectType.id !== 'OT_Product') {
          this.router.navigate(['/'], { skipLocationChange: true });
          return;
        }
        return this.setProduct(product);
      }),
    );
  }

  private setProduct(product: Product): ProductData {
    return {
      headerData: this.getHeaderData(product),
      productTypes: this.getProductTypes(product),
    };
  }

  private getHeaderData(product: Product): HeaderData {
    const classification = product.referencesByReferenceType?.find((ref) => {
      return ref.referenceType.id === environment.pim.classificationRef;
    })?.referenceEntries?.[0]?.target;

    const path: PathElement[] | undefined = classification?.path
      .slice(
        classification.path.findIndex((path) => {
          return path.id === environment.pim.topClassification;
        }),
      )
      .map((x) => {
        return {
          title: x.title,
          link: this.routePipe.transform(x.id, x.objectType.id),
        };
      });

    if (classification) {
      path?.push({
        title: classification.title,
        link: this.routePipe.transform(
          classification.id,
          classification.objectType.id,
        ),
      });
    }
    path?.push({
      title: product.title,
      link: this.routePipe.transform(product.id, product.objectType.id),
    });

    const icon = product.referencesByReferenceType
      ?.find((ref) => ref.referenceType.id === 'REF_Branding_Icon')
      ?.referenceEntries.find((img) =>
        img.target.values
          ?.find((val) => val.attribute.id === 'AT_IconType')
          ?.values.some((val) => val.valueId === 'white BG vitorange'),
      )
      ?.target.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue;

    let backLink;
    if (path && path.length > 1) {
      backLink = path[path.length - 2].link;
    }

    return {
      title: product.title,
      backLink: backLink,
      breadcrumbs: path,
      iconUrl: icon,
    };
  }

  private buildDescription(productType: Product): string | undefined {
    const relevantAttributes = this.sharedService.isCurrentContextMaster()
      ? this.validMasterDescriptionAttributes
      : this.validDescriptionAttributes;
    const descriptionValues = productType.values?.filter((val) =>
      relevantAttributes.includes(val.attribute.id),
    );

    let first = true;

    return (
      descriptionValues?.reduce<string>((desc, value) => {
        if (value.values && value.values.length > 0) {
          if (!first) desc += '\n';

          const joinedValues = value.values.map((val) => val.value).join(', ');

          desc += `${value.attribute.title}: ${joinedValues}`;

          const metadataScale = value?.attribute.values?.find(
            (metaVal) => metaVal.attribute.id === 'AT_EfficiencyRange',
          )?.simpleValue;
          if (metadataScale) desc += ` (${metadataScale})`;
          first = false;
        }
        return desc;
      }, '') ?? undefined
    );
  }

  private getProductTypes(product: Product): CardData[] {
    return product.children.pageElements.reduce<CardData[]>(
      (productTypes, productType) => {
        if (this.sharedService.isValidForMarket(productType)) {
          productTypes.push({
            title: productType.title,
            link: this.routePipe.transform(
              productType.id,
              productType.objectType.id,
            ),
            imgLink: productType.primaryImage?.values?.find(
              (val) =>
                val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_1200',
            )?.simpleValue,
            description: this.buildDescription(productType),
          });
        }
        return productTypes;
      },
      [],
    );
  }
}
