import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderModule } from '@vi/global-header';
import { PimRoutePipe } from '../pipes/pim-route.pipe';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NavHeaderComponent } from './nav-header.component';
import { environment } from 'src/environments/environment';

const material = [
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatInputModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [NavHeaderComponent, SearchBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    GlobalHeaderModule.forRoot({
      appId: environment.auth.appId,
      baseUrl: environment.globalHeader.baseUrl,
      accountAppUrl: environment.globalHeader.accountAppUrl,
    }),
    ...material,
    MatSelect,
  ],
  exports: [NavHeaderComponent],
  providers: [PimRoutePipe],
})
export class NavHeaderModule {}
