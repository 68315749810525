<div class="search-bar">
  <div
    class="product-search-input"
    [class.product-search-input-focused]="inputFocused"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon (click)="searchBarInput.focus()">search</mat-icon>
    <input
      fxFlex
      type="text"
      #searchBarInput
      [matAutocomplete]="auto"
      [formControl]="searchInputControl"
      (focusin)="focusInCheck()"
      (focusout)="focusOutCheck(searchBarInput.value)"
      placeholder="{{ 'nav_header.search.bar' | translate }}"
    />

    <ng-container *ngIf="inputContainsText">
      <mat-icon
        *ngIf="!loading; else spinner"
        (click)="onCloseClick(searchBarInput)"
      >close</mat-icon
      >
      <ng-template #spinner>
        <mat-spinner class="autocomplete-spinner" [diameter]="24"></mat-spinner>
      </ng-template>
    </ng-container>

    <mat-autocomplete
      #auto="matAutocomplete"
      class="autocomplete"
      (optionSelected)="goToResult($event.option.value)"
      [displayWith]="displayResult"
    >
      <ng-container *ngFor="let group of suggestions$ | async">
        <mat-optgroup [label]="'nav_header.search.object-type.' + group.objectTypeId | translate">
          <mat-option *ngFor="let result of group.products" [value]="result">
            {{ result.name }}
            {{ result.description }}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
  </div>
</div>
