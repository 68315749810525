import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Product } from '../../pim-graphql.model';
import { PimGraphqlService } from '../../pim-graphql.service';
import {
  HeaderData,
  ListEntry,
  ListEntryAttribute,
  PathElement,
} from '../shared-view.model';
import { SharedViewService } from '../shared-view.service';
import { PackageTypeData } from './package-type.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PackageTypeService {
  constructor(
    private pim: PimGraphqlService,
    private router: Router,
    private sharedService: SharedViewService,
    private routePipe: PimRoutePipe,
  ) {}

  getPackageTypeData(id: string): Observable<PackageTypeData | undefined> {
    return this.pim.getPackageTypeById(id).pipe(
      map((packageType) => {
        if (!packageType || packageType.objectType.id !== 'OT_PackageType') {
          this.router.navigate(['/'], { skipLocationChange: true });
          return;
        }
        return this.setPackageType(packageType);
      }),
    );
  }

  private setPackageType(packageType: Product): PackageTypeData {
    return {
      headerData: this.getHeaderData(packageType),
      packages: this.getPackages(packageType),
    };
  }

  private getPackages(packageType: Product): ListEntry[] {
    const validAttributes = [
      'AT_MAKTX',
      'AT_Shoptext_calc',
      'AT_SAP_E_EFFIZIENZKLASSE_H',
      'AT_SAP_E_EFFIZIENZKLASSE_W',
      'AT_SAP_E_EFFIZIENZKLASSE_S',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_1',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_095',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_085',
    ];
    const displayableValues: string[] = [];
    const rawPackages = packageType.children?.pageElements;

    validAttributes.forEach((validAttr) => {
      for (let i = 0; i < rawPackages.length; i++) {
        if (
          rawPackages[i].values?.find((val) => val.attribute.id === validAttr)
            ?.simpleValue
        ) {
          displayableValues.push(validAttr);
          break;
        }
      }
    });

    return rawPackages?.reduce<ListEntry[]>((packages, pack) => {
      const attribute = displayableValues.reduce<ListEntryAttribute[]>(
        (attributes, attr) => {
          const value = pack.values?.find((val) => val.attribute.id === attr);

          // Special logic for effiency ratings AT_SAP_E_EFFIZIENZKLASSE_...
          const metadataScale = value?.attribute.values?.find(
            (metaVal) => metaVal.attribute.id === 'AT_EfficiencyRange',
          )?.simpleValue;

          if (value?.simpleValue) {
            const displayValue = metadataScale
              ? `${value.simpleValue} (${metadataScale})`
              : value?.simpleValue;

            attributes.push({
              id: value.attribute.id,
              title: value.attribute.title,
              value: displayValue,
            });
          }
          return attributes;
        },
        [],
      );

      if (this.sharedService.isValidForMarket(pack)) {
        packages.push({
          id: pack.id,
          title: pack.title,
          link: this.routePipe.transform(pack.id, pack.objectType.id),
          attributes: attribute,
        });
      }
      return packages;
    }, []);
  }

  private getHeaderData(packageType: Product): HeaderData {
    const classification = packageType.referencesByReferenceType?.find(
      (ref) => {
        return ref.referenceType.id === environment.pim.classificationRef;
      },
    )?.referenceEntries?.[0]?.target;

    const path: PathElement[] | undefined = classification?.path
      .slice(
        classification.path.findIndex((path) => {
          return path.id === environment.pim.topClassification;
        }),
      )
      .map((x) => {
        return {
          title: x.title,
          link: this.routePipe.transform(x.id, x.objectType.id),
        };
      });

    if (classification) {
      path?.push({
        title: classification.title,
        link: this.routePipe.transform(
          classification.id,
          classification.objectType.id,
        ),
      });
    }
    path?.push({
      title: packageType.title,
      link: this.routePipe.transform(packageType.id, packageType.objectType.id),
    });

    const icon = packageType.referencesByReferenceType
      ?.find((ref) => ref.referenceType.id === 'REF_Branding_Icon')
      ?.referenceEntries.find((img) =>
        img.target.values
          ?.find((val) => val.attribute.id === 'AT_IconType')
          ?.values.some((val) => val.valueId === 'white BG vitorange'),
      )
      ?.target.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue;

    let backLink;
    if (path && path.length > 1) {
      backLink = path[path.length - 2].link;
    }

    return {
      title: packageType.title,
      backLink: backLink,
      breadcrumbs: path,
      iconUrl: icon,
    };
  }
}
