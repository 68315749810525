import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PimService } from '../../../../services/pim.service';
import { PimProduct, SearchResultGroup } from './search.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private pim: PimService) {}

  search(term: string): Observable<SearchResultGroup[]> {
    return this.pim.searchByTerm(term).pipe(
      map((products) => {
        return this.setSearchResult(products);
      }),
    );
  }

  getUniqueTypes(suggestions: PimProduct[]): string[] {
    const types = suggestions.map((item) => item.type);
    return [...new Set(types)];
  }

  private setSearchResult(products: PimProduct[]): SearchResultGroup[] {
    const uniqueTypes = this.getUniqueTypes(products);

    return uniqueTypes.map((objectTypeId) => {
      const groupProducts = products.filter(
        (item) => item.type === objectTypeId,
      );
      return <SearchResultGroup>{
        objectTypeId: objectTypeId,
        products: groupProducts,
      };
    });
  }
}
