import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { LocaleService } from './services/locale.service';
import { ValidityDateService } from './services/validity-date.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('drawer_content', { static: false }) el!: MatSidenavContent;
  title = 'fe-product-browser';
  private routeSubscription?: Subscription;
  public forbidden = false;
  private firstLoad = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    public localeService: LocaleService,
    private validityDateService: ValidityDateService,
  ) {}

  ngOnInit(): void {
    this.authService.init();
    //Workaround for scrollPositionRestoration not working inside mat-drawer-content
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.el?.getElementRef().nativeElement) {
          this.el.getElementRef().nativeElement.scrollTop = 0;
        }
        if (this.router.url.includes('forbidden')) {
          this.forbidden = true;
        } else if (this.firstLoad) {
          this.firstLoad = false;
          this.validityDateService.setValidityDateParam();
        }
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}
