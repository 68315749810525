import { Component, Input, OnChanges } from '@angular/core';
import {
  ListEntry,
  ListEntryAttribute,
  ListEntryImage,
} from '../../pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-shared-list',
  templateUrl: './shared-list.component.html',
  styleUrls: ['./shared-list.component.scss'],
})
export class SharedListComponent implements OnChanges {
  @Input() entries?: ListEntry[];
  dataSource: ListEntry[] = [];
  displayedColumns: string[] = [];
  attributes: ListEntryAttribute[] = [];
  image?: ListEntryImage;

  ngOnChanges(): void {
    if (this.entries) {
      this.dataSource = this.entries;
      this.displayedColumns = ['title'];
      this.addImage();
      this.setAttributes();
    }
  }

  private setAttributes() {
    if (this.dataSource.length > 0) {
      this.attributes = this.dataSource.reduce<ListEntryAttribute[]>(
        (aggregatedAttrs, row) => {
          row.attributes.forEach((attr) => {
            if (!aggregatedAttrs.find((aggrAttr) => aggrAttr.id === attr.id))
              aggregatedAttrs.push(attr);
          });
          return aggregatedAttrs;
        },
        [],
      );
      this.displayedColumns = [
        ...this.displayedColumns,
        ...this.attributes.map((attr) => attr.id),
      ];
    }
  }

  private addImage() {
    const entryImage = this.dataSource.find((set) => {
      return !!set.image;
    });
    if (!!entryImage && !!entryImage.image) {
      this.displayedColumns.push(entryImage.image?.id);
      this.image = entryImage.image;
    }
  }

  getValue(entry: ListEntry, id: string): string | undefined {
    return entry.attributes?.find((value) => value.id === id)?.value;
  }
}
