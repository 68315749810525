import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Classification, Product } from './pim-graphql.model';
import { LocaleService } from 'src/app/services/locale.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { environment } from 'src/environments/environment';

const GET_CLASSIFICATION = gql`
  query classification(
    $id: String!
    $context: String!
    $workspace: String!
    $classRef: String!
  ) {
    classification(context: $context, workspace: $workspace, id: $id) {
      id
      title

      objectType {
        id
      }

      path {
        id
        title
        objectType {
          id
        }
      }

      parent {
        id
        title
      }

      children(sorting: { simpleValue: { attribute: "AT_Position" } }) {
        pageElements {
          id
          title
          objectType {
            id
          }
          valuesByDataTypeGroup(
            dataTypeGroups: ["ATG_AppStructureValidities"]
          ) {
            dataTypeGroup {
              id
            }
            values {
              attribute {
                id
              }
              simpleValue
            }
          }
        }
      }

      incomingReferencesByReferenceType(referenceTypes: [$classRef]) {
        referenceType {
          id
        }
        referenceEntries(
          sorting: { metadataSimpleValue: { attribute: "AT_Position" } }
        ) {
          pageElements {
            values(attributes: "AT_Position") {
              attribute {
                id
              }
              simpleValue
            }
            source {
              ... on Product {
                id
                title
                objectType {
                  id
                }
                values(
                  attributes: [
                    "AT_MAKTX"
                    "AT_Shoptext_calc"
                    "AT_SalesOrg_multi"
                    "AT_Efficiency_WarmWater"
                    "AT_Efficiency_Heating"
                    "AT_Efficiency_Storage"
                    "AT_Efficiency_WarmWater_global"
                    "AT_Efficiency_Heating_global"
                    "AT_Efficiency_Storage_global"
                    "AT_SEV_D_085"
                    "AT_SEV_D_095"
                    "AT_SEV_D_1"
                    "AT_SEV_D_085_global"
                    "AT_SEV_D_095_global"
                    "AT_SEV_D_1_global"
                  ]
                ) {
                  attribute {
                    id
                    title
                    values(attributes: "AT_EfficiencyRange") {
                      attribute {
                        id
                      }
                      simpleValue
                    }
                  }
                  values {
                    value
                  }
                }
                referencesByReferenceType(
                  referenceTypes: "PrimaryProductImage"
                ) {
                  referenceType {
                    id
                    title
                  }
                  referenceEntries {
                    target {
                      ... on Asset {
                        id
                        title
                        values(
                          attributes: [
                            "AT_AssetPush_Conversion_URL_PNG_1200"
                            "AT_AssetPush_Conversion_URL_PNG_400"
                          ]
                        ) {
                          attribute {
                            id
                          }
                          simpleValue
                        }
                      }
                    }
                  }
                }
                dataContainers(dataContainerTypes: "DC_SalesOrg") {
                  dataContainerType {
                    id
                  }
                  dataContainers {
                    values(
                      attributes: [
                        "AT_SalesOrg_Desc"
                        "AT_ValidUntil"
                        "AT_ValidFrom"
                      ]
                    ) {
                      attribute {
                        id
                      }
                      simpleValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query product(
    $id: String!
    $salesOrg: String!
    $context: String!
    $workspace: String!
    $classRef: String!
  ) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      title

      objectType {
        id
      }

      children(
        filter: {
          simpleValue: {
            attribute: "AT_SalesOrg_multi"
            operator: contains
            queryString: $salesOrg
          }
        }
        sorting: { simpleValue: { attribute: "AT_Position_App_Structure" } }
      ) {
        pageElements {
          id
          title
          objectType {
            id
          }
          values(
            attributes: [
              "AT_Efficiency_WarmWater"
              "AT_Efficiency_Heating"
              "AT_Efficiency_Storage"
              "AT_Efficiency_WarmWater_global"
              "AT_Efficiency_Heating_global"
              "AT_Efficiency_Storage_global"
              "AT_SEV_D_085"
              "AT_SEV_D_095"
              "AT_SEV_D_1"
              "AT_SEV_D_085_global"
              "AT_SEV_D_095_global"
              "AT_SEV_D_1_global"
            ]
          ) {
            attribute {
              id
              title
              values(attributes: "AT_EfficiencyRange") {
                attribute {
                  id
                }
                simpleValue
              }
            }
            values {
              value
            }
          }
          primaryImage {
            values(attributes: "AT_AssetPush_Conversion_URL_PNG_1200") {
              attribute {
                id
              }
              simpleValue
            }
          }
          dataContainers(dataContainerTypes: "DC_SalesOrg") {
            dataContainerType {
              id
            }
            dataContainers {
              values(
                attributes: [
                  "AT_SalesOrg_Desc"
                  "AT_ValidUntil"
                  "AT_ValidFrom"
                ]
              ) {
                attribute {
                  id
                }
                simpleValue
              }
            }
          }
        }
      }

      referencesByReferenceType(
        referenceTypes: [$classRef, "REF_Branding_Icon"]
      ) {
        referenceType {
          id
        }
        referenceEntries {
          target {
            ... on Classification {
              title
              id
              objectType {
                id
              }
              path {
                id
                title
                objectType {
                  id
                }
              }
            }
            ... on Asset {
              values(
                attributes: [
                  "AT_AssetPush_Conversion_URL_PNG_400"
                  "AT_IconType"
                ]
              ) {
                attribute {
                  id
                }
                values {
                  valueId
                }
                simpleValue
              }
            }
          }
        }
      }
    }
  }
`;

const GET_PRODUCT_TYPE = gql`
  query product_type(
    $id: String!
    $context: String!
    $workspace: String!
    $salesOrg: String!
    $classRef: String!
    $imageRef: String!
  ) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      title

      objectType {
        id
      }

      path {
        id
        title
        objectType {
          id
        }
      }

      children(
        filter: {
          simpleValue: {
            attribute: "AT_SalesOrg_multi"
            operator: contains
            queryString: $salesOrg
          }
        }
        sorting: { simpleValue: { attribute: "AT_Position" } }
      ) {
        pageElements {
          id
          title

          objectType {
            id
          }

          values(
            attributes: [
              "AT_MAKTX"
              "AT_Shoptext_calc"
              "AT_SAP_E_EFFIZIENZKLASSE_H"
              "AT_SAP_E_EFFIZIENZKLASSE_W"
              "AT_SAP_E_EFFIZIENZKLASSE_S"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_1"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_095"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_085"
            ]
          ) {
            attribute {
              title
              id
              values(attributes: "AT_EfficiencyRange") {
                attribute {
                  id
                }
                simpleValue
              }
            }
            simpleValue
          }

          referencesByReferenceType(referenceTypes: [$imageRef]) {
            referenceType {
              id
              title
            }
            referenceEntries(sorting: { targetId: { descending: true } }) {
              target {
                ... on Asset {
                  values(
                    attributes: [
                      "AT_AssetPush_Conversion_URL_PNG_400"
                      "AT_ProductImage_subtype"
                    ]
                  ) {
                    attribute {
                      id
                    }
                    values {
                      valueId
                    }
                    simpleValue
                  }
                }
              }
            }
          }
          dataContainers(dataContainerTypes: "DC_SalesOrg") {
            dataContainerType {
              id
            }
            dataContainers {
              values(
                attributes: [
                  "AT_SalesOrg_Desc"
                  "AT_ValidUntil"
                  "AT_ValidFrom"
                ]
              ) {
                attribute {
                  id
                }
                simpleValue
              }
            }
          }
        }
      }

      parent {
        id
        title
        referencesByReferenceType(referenceTypes: [$classRef]) {
          referenceType {
            id
          }
          referenceEntries {
            target {
              ... on Classification {
                id
                title
                objectType {
                  id
                }
                path {
                  id
                  title
                  objectType {
                    id
                  }
                }
              }
            }
          }
        }
      }

      referencesByReferenceType(referenceTypes: ["REF_Branding_Icon"]) {
        referenceType {
          id
        }
        referenceEntries {
          target {
            ... on Asset {
              values(
                attributes: [
                  "AT_AssetPush_Conversion_URL_PNG_400"
                  "AT_IconType"
                ]
              ) {
                attribute {
                  id
                }
                values {
                  valueId
                }
                simpleValue
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_IMAGES = gql`
  query images($id: String!, $context: String!, $workspace: String!) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      referencesByReferenceType(
        referenceTypes: [
          "PrimaryProductImage"
          "PrimaryProductMilieu"
          "REF_PrimaryPerspective"
          "REF_PrimaryFrontal"
          "REF_Assets4PS"
          "REF_AddAssets4PS"
        ]
      ) {
        referenceType {
          id
          title
        }
        referenceEntries(sorting: { targetId: { descending: true } }) {
          target {
            ... on Asset {
              id
              title
              objectType {
                id
              }
              values(
                attributes: [
                  "AT_AssetPush_Conversion_URL_PNG_1200"
                  "AT_ProductImage_subtype"
                ]
              ) {
                attribute {
                  id
                }
                simpleValue
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_RELATIONS = gql`
  query relations($id: String!, $context: String!, $workspace: String!) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      incomingReferencesByReferenceType(referenceTypes: "REF_Packet") {
        referenceType {
          id
          title
        }
        referenceEntries {
          pageElements {
            source {
              ... on Product {
                id
                title
                objectType {
                  id
                }
                values(attributes: "AT_MAKTX") {
                  attribute {
                    id
                  }
                  simpleValue
                }
                primaryImage {
                  values(attributes: "AT_AssetPush_Conversion_URL_PNG_400") {
                    attribute {
                      id
                    }
                    simpleValue
                  }
                }
              }
            }
          }
        }
      }
      referencesByReferenceType(
        referenceTypes: [
          "REF_Accessories"
          "REF_Services"
          "REF_Packet"
          "REF_DigitalService"
          "REF_AlternativeProducts"
        ]
      ) {
        referenceType {
          id
          title
        }
        referenceEntries(
          sorting: { metadataSimpleValue: { attribute: "AT_PosId" } }
        ) {
          values(attributes: ["AT_PosId"]) {
            attribute {
              id
            }
            values {
              value
            }
          }
          target {
            ... on Product {
              id
              title
              objectType {
                id
              }
              values(attributes: ["AT_MAKTX", "AT_SAP_V_GRUPPENMERKMAL2"]) {
                attribute {
                  id
                  title
                }
                simpleValue
              }
              primaryImage {
                values(attributes: "AT_AssetPush_Conversion_URL_PNG_400") {
                  attribute {
                    id
                  }
                  simpleValue
                }
              }
            }
          }
        }
      }
      children {
        pageElements {
          ... on Product {
            id
            title
            objectType {
              id
            }
            referencesByReferenceType(
              referenceTypes: [
                "REF_SalesPartsList"
                "REF_SalesPartsListComponentGroup"
              ]
            ) {
              referenceType {
                id
                title
              }
              referenceEntries(
                sorting: { metadataSimpleValue: { attribute: "AT_PosId" } }
              ) {
                values(
                  attributes: [
                    "AT_PosId"
                    "AT_StartDate"
                    "AT_EndDate"
                    "AT_Amount"
                  ]
                ) {
                  attribute {
                    id
                    validation {
                      name
                    }
                  }
                  values {
                    value
                    unit {
                      title
                    }
                  }
                }
                target {
                  ... on Product {
                    id
                    title
                    objectType {
                      id
                    }
                    values(attributes: "AT_MAKTX") {
                      attribute {
                        id
                      }
                      simpleValue
                    }
                    primaryImage {
                      values(
                        attributes: "AT_AssetPush_Conversion_URL_PNG_400"
                      ) {
                        attribute {
                          id
                        }
                        simpleValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_TEXTS = gql`
  query texts($id: String!, $context: String!, $workspace: String!) {
    product(context: $context, workspace: $workspace, id: $id) {
      id

      values(attributes: ["AT_OfferingText", "AT_OfferingTextLong"]) {
        attribute {
          title
          id
        }
        simpleValue
      }

      valuesByDataTypeGroup(dataTypeGroups: ["ATG_SellingAttributes_Texts"]) {
        dataTypeGroup {
          id
          title
        }
        values {
          attribute {
            title
            id
          }
          values {
            value
          }
        }
      }

      referencesByReferenceType(
        referenceTypes: [
          "REF_BenefitText_B2B"
          "REF_BenefitText_B2C"
          "REF_TechnologyDescription"
          "REF_FeatureDescription"
          "REF_BenefitBranding"
        ]
      ) {
        referenceType {
          id
          title
        }
        referenceEntries(
          sorting: { metadataSimpleValue: { attribute: "AT_PosNo" } }
        ) {
          values(attributes: ["AT_PosNo"]) {
            attribute {
              id
            }
            simpleValue
          }
          target {
            ... on Product {
              id
              title
              values(attributes: ["AT_TB_Headline", "AT_TB_Content"]) {
                attribute {
                  id
                  title
                }
                simpleValue
              }
              referencesByReferenceType(referenceTypes: ["REF_Branding_Icon"]) {
                referenceType {
                  id
                }
                referenceEntries {
                  target {
                    ... on Asset {
                      values(
                        attributes: [
                          "AT_AssetPush_Conversion_URL_PNG_400"
                          "AT_IconType"
                        ]
                      ) {
                        attribute {
                          id
                        }
                        values {
                          valueId
                        }
                        simpleValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_TECHNICAL_ATTRIBUTES = gql`
  query technical_attributes(
    $id: String!
    $context: String!
    $workspace: String!
  ) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      valuesByDataTypeGroup(
        sorting: { id: { descending: false } }
        dataTypeGroups: [
          "ATG_SAP_Attribute"
          "ATG_prioSapAttributes_1"
          "ATG_prioSapAttributes_2"
          "ATG_prioSapAttributes_3"
          "ATG_prioSapAttributes_4"
          "ATG_prioSapAttributes_5"
          "ATG_prioSapAttributes_6"
        ]
      ) {
        dataTypeGroup {
          title
          id
        }
        values {
          attribute {
            id
            title
            validation {
              name
            }
          }
          values {
            valueId
            value
            unit {
              title
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_USAGE_EXAMPLE = gql`
  query usage_example($id: String!, $context: String!, $workspace: String!) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      title
      referencesByReferenceType(referenceTypes: ["REF_ApplicationExample"]) {
        referenceType {
          id
          title
        }
        referenceEntries(sorting: { targetId: { descending: true } }) {
          target {
            ... on Product {
              id
              title
              values(attributes: ["AT_TB_Headline", "AT_TB_Content"]) {
                attribute {
                  id
                  title
                }
                simpleValue
              }
              referencesByReferenceType(
                referenceTypes: ["REF_SystemImage", "REF_Accessories"]
              ) {
                referenceType {
                  id
                  title
                }
                referenceEntries {
                  target {
                    ... on Product {
                      id
                      title
                      values(attributes: "AT_MAKTX") {
                        attribute {
                          id
                        }
                        simpleValue
                      }
                    }
                    ... on Asset {
                      values(
                        attributes: [
                          "AT_AssetPush_Conversion_URL_PNG_1200"
                          "AT_AssetPush_Conversion_URL_PNG_400"
                        ]
                      ) {
                        attribute {
                          id
                        }
                        simpleValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL_LEGEND_TEXTS = gql`
  query legend_texts($id: String!, $context: String!, $workspace: String!) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      referencesByReferenceType(referenceTypes: "REF_LegendImage") {
        referenceType {
          id
          title
        }
        referenceEntries {
          target {
            ... on Product {
              id
              title
              primaryImage {
                title
                values(attributes: "AT_AssetPush_Conversion_URL_PNG_1200") {
                  attribute {
                    id
                  }
                  simpleValue
                }
              }
              values(attributes: "AT_Legend") {
                attribute {
                  id
                }
                values {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DETAIL = gql`
  query detail(
    $id: String!
    $context: String!
    $workspace: String!
    $classRef: String!
  ) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      title

      objectType {
        id
      }

      path {
        id
        title

        objectType {
          id
        }

        referencesByReferenceType(referenceTypes: [$classRef]) {
          referenceType {
            id
          }
          referenceEntries {
            target {
              ... on Classification {
                title
                id
                objectType {
                  id
                }
                path {
                  id
                  title
                  objectType {
                    id
                  }
                }
              }
            }
          }
        }
      }

      referencesByReferenceType(
        referenceTypes: ["REF_Branding_Icon", $classRef]
      ) {
        referenceType {
          id
        }
        referenceEntries {
          target {
            ... on Asset {
              values(
                attributes: [
                  "AT_AssetPush_Conversion_URL_PNG_400"
                  "AT_IconType"
                ]
              ) {
                attribute {
                  id
                }
                values {
                  valueId
                }
                simpleValue
              }
            }
            ... on Classification {
              title
              id
              objectType {
                id
              }
              path {
                id
                title
                objectType {
                  id
                }
              }
            }
          }
        }
      }

      values(attributes: ["AT_MAKTX"]) {
        attribute {
          title
          id
        }
        simpleValue
      }
    }
  }
`;

const GET_PACKAGE_TYPE = gql`
  query package_type(
    $id: String!
    $context: String!
    $workspace: String!
    $salesOrg: String!
    $classRef: String!
  ) {
    product(context: $context, workspace: $workspace, id: $id) {
      id
      title
      objectType {
        id
      }
      path {
        id
        title
        objectType {
          id
        }
      }
      children(
        filter: {
          simpleValue: {
            attribute: "AT_SalesOrg_multi"
            operator: contains
            queryString: $salesOrg
          }
        }
        sorting: { simpleValue: { attribute: "AT_Position" } }
      ) {
        pageElements {
          id
          title
          objectType {
            id
          }
          values(
            attributes: [
              "AT_MAKTX"
              "AT_Shoptext_calc"
              "AT_SAP_E_EFFIZIENZKLASSE_H"
              "AT_SAP_E_EFFIZIENZKLASSE_W"
              "AT_SAP_E_EFFIZIENZKLASSE_S"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_1"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_095"
              "AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_085"
            ]
          ) {
            attribute {
              title
              id
              values(attributes: "AT_EfficiencyRange") {
                attribute {
                  id
                }
                simpleValue
              }
            }
            simpleValue
          }
          dataContainers(dataContainerTypes: "DC_SalesOrg") {
            dataContainerType {
              id
            }
            dataContainers {
              values(
                attributes: [
                  "AT_SalesOrg_Desc"
                  "AT_ValidUntil"
                  "AT_ValidFrom"
                ]
              ) {
                attribute {
                  id
                }
                simpleValue
              }
            }
          }
        }
      }
      referencesByReferenceType(
        referenceTypes: ["REF_Branding_Icon", $classRef]
      ) {
        referenceType {
          id
        }
        referenceEntries {
          target {
            ... on Asset {
              values(
                attributes: [
                  "AT_AssetPush_Conversion_URL_PNG_400"
                  "AT_IconType"
                ]
              ) {
                attribute {
                  id
                }
                values {
                  valueId
                }
                simpleValue
              }
            }
            ... on Classification {
              title
              id
              objectType {
                id
              }
              path {
                id
                title
                objectType {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PimGraphqlService {
  constructor(
    private apollo: Apollo,
    private locales: LocaleService,
    private workspaces: WorkspaceService,
  ) {}

  getClassificationById(id: string): Observable<Classification> {
    return this.apollo
      .query<{ classification: Classification }>({
        query: GET_CLASSIFICATION,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
          classRef: environment.pim.classificationRef,
        },
      })
      .pipe(map((response) => response.data.classification));
  }

  getProductById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_PRODUCT,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
          salesOrg: this.locales.getCurrentLocale().salesOrg,
          classRef: environment.pim.classificationRef,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductTypeById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_PRODUCT_TYPE,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
          salesOrg: this.locales.getCurrentLocale().salesOrg,
          classRef: environment.pim.classificationRef,
          imageRef: environment.pim.productType.productImageRef,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getDetailById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
          classRef: environment.pim.classificationRef,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductImagesById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_IMAGES,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductRelationsById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_RELATIONS,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductTextsById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_TEXTS,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductUsageExampleById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_USAGE_EXAMPLE,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductLegendTextById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_LEGEND_TEXTS,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getProductAttributesById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_DETAIL_TECHNICAL_ATTRIBUTES,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
        },
      })
      .pipe(map((response) => response.data.product));
  }

  getPackageTypeById(id: string): Observable<Product> {
    return this.apollo
      .query<{ product: Product }>({
        query: GET_PACKAGE_TYPE,
        variables: {
          context: this.locales.getCurrentLocaleString(),
          workspace: this.workspaces.getCurrentWorkspace(),
          id: id,
          salesOrg: this.locales.getCurrentLocale().salesOrg,
          classRef: environment.pim.classificationRef,
        },
      })
      .pipe(map((response) => response.data.product));
  }
}
