<a [routerLink]="data.link">
  <mat-card fxLayout="column" class="classification-card">
    <ng-container *ngIf="!!data.imgLink">
      <div [fxHide]="loading" class="img-container">
        <img [src]="data.imgLink" (load)="loading = false" />
      </div>
      <ngx-skeleton-loader
        *ngIf="loading"
        [theme]="{
          height: '250px',
          'border-radius': '4px 4px 0 0',
          'margin-bottom': '0'
        }"
      >
      </ngx-skeleton-loader>
    </ng-container>
    <mat-card-header fxFlex fxLayout="column" fxLayoutAlign="center center">
      <mat-card-title class="shared-card-title" fxFlex>{{
        data.title
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!!data.description">
      <p class="shared-card-description">{{ data.description }}</p>
    </mat-card-content>
  </mat-card>
</a>
